@import "./abstracts/theme.module";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap");

:root {
  @include printVariables();
}

body,
html {
  overflow-x: hidden;
}

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body,
html {
  width: 100%;
}

.errText {
  color: var(--errorMain) !important;
}

body {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  width: 100%;
  font-weight: normal;
  font-style: normal;
  color: #000;
  font-size: 16px;
  line-height: 1.5;
  background: #fbfaff;
}

b,
strong {
  font-weight: 700;
}

p {
  margin: 0 0 22px 0;
}

p:last-child {
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 25px 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1.2;
  color: #1a1a1a;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0;
}

h1 {
  font-size: 50px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-weight: 500;
  font-size: 18px;
  color: #383838;
}

a {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  outline: none !important;
  //color: #ecb61e;
  color: #000;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

// a:hover {
//   color: red;
// }

img {
  max-width: 100%;
}

input[type="text"],
input[type="url"],
input[type="tel"],
input[type="password"],
input[type="number"],
input[type="email"] input[type="date"] {
  appearance: none;
  outline: none !important;
  padding: 10px 12px;
  width: 100%;
  height: 46.8px;
  background: #ffffff;
  border: 1px solid #8d99ae;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000;
}

.new_calender input {
  height: 25.8px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

textarea {
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 1px solid rgba(255, 255, 255, 0.7);
  padding: 12px 16px;
  width: 100%;
  color: #000;
  height: 158px;
  outline: none !important;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

select {
  outline: none !important;
  padding: 10px 37px;
  width: 100%;
  height: 44px;
  background: #ffffff;
  border: 1px solid #8d99ae;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000;
  background-image: url(../public/assets/images/downArrow.svg);
  background-repeat: no-repeat;
  background-position: center right 10px;
  appearance: none;
  cursor: pointer;

  .sfg {
    padding: 10px 22px !important;
  }
}

.inputWrapper {
  select {
    padding: 10px 14px !important;
  }
}

.calender_icon {
  svg {
    position: absolute;
    top: 10px;
    margin-left: 7px;
  }
}

select::-ms-expand {
  display: none;
}

input[type="submit"] {
  background: #eab71e;
  color: #000000;
  height: 58px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  padding-left: 30px;
  padding-right: 30px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 24px;
  font-weight: 700;
  outline: none !important;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input[type="submit"]:hover {
  background: #000;
  color: #eab71e;
}

.MuiTouchRipple-root {
  display: none;
}

.pfMenu {
  .MuiPopover-paper {
    box-shadow: none;
    border: 1px solid#eee;
  }
}

.dialogBox {
  .MuiPaper-rounded {
    padding: 20px 73px;
    position: relative;
    max-width: 519px;
    flex-basis: 519px;
    background: #ffffff;
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.07);
    border-radius: 10px;

    @media (max-width: 575px) {
      padding: 10px 10px;
    }
  }

  .MuiIconButton-root {
    background: #eeeeee;
    color: #777777;
  }

  .MuiTypography-h6 {
    padding: 0;
  }
}

.dialogBoxWithOutBorder {
  .MuiPaper-rounded {
    padding: 0;
    position: relative;
    max-width: 668px;
    flex-basis: 668px;
    background: #ffffff;
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
  }

  .MuiIconButton-root {
    background: #eeeeee;
    color: #777777;
  }

  .MuiTypography-h6 {
    padding: 0;
  }
}

.adsModal {
  .MuiPaper-rounded {
    padding: 72px 24px 25px;
    position: relative;
    max-width: 632px;
    flex-basis: 632px;
    background: #f4f7f6;
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
  }

  .MuiIconButton-root {
    display: none;
  }
}

.draftServiceMenu {
  .MuiPopover-paper {
    width: 100%;
    max-width: 144px;
    border-radius: 8px;
  }
}

.activeserviceMenu {
  .MuiPopover-paper {
    width: 100%;
    max-width: 144px;
    border-radius: 8px;
  }
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #ddd;
}

::-webkit-scrollbar-thumb {
  background: #d90429;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #d90429;
}

.partnerMenu {
  .MuiMenu-paper {
    width: 104px;
  }
}

.partnershipModal {
  .MuiPaper-rounded {
    padding: 72px 26px 25px;
    position: relative;
    max-width: 632px;
    flex-basis: 632px;
    background: #ffffff;
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.07);
    border-radius: 10px;

    @media (max-width: 575px) {
      padding: 30px 20px 20px;
    }
  }

  .MuiIconButton-root {
    background: transparent;
  }
}

////////cmn-table

.cmnTable {
  .MuiPaper-rounded {
    border-radius: 0;
  }

  .MuiTableCell-head {
    background-color: #7695a1 !important;
    color: #fff;
    padding: 11px;
    font-weight: 400;
    border-width: 10px;
    white-space: nowrap;

    @media (max-width: 1199px) {
      padding: 5px;
    }
  }

  .MuiTableCell-body {
    @media (max-width: 1199px) {
      font-size: 13px;
      padding: 5px;
    }
  }

  &.servicemain {
    >div {
      box-shadow: none !important;
      border: 0px;
      background: none !important;
    }

    table {
      border-spacing: 0px 20px !important;
      border-collapse: separate !important;
      border: 0px;

      td {
        border: 0px;
      }
    }

    thead {
      th {
        border: 0px;
      }

      th:first-child {
        border-radius: 6px 0 0 6px;
        border: 0px;
        padding-left: 62px;
      }

      th:last-child {
        border-radius: 0 6px 6px 0;
        border: 0px;
      }
    }

    tbody {
      td {
        border: 0px;
      }

      td:first-child {
        border-radius: 6px 0 0 6px;
        border: 0px;
        padding-left: 62px;
      }

      td:last-child {
        border-radius: 0 6px 6px 0;
        border: 0px;
      }
    }

    tbody {

      // border-top: 10px solid transparent;
      tr {
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
        // border-bottom: 20px soloid transparent;
        filter: drop-shadow(0px 0px 12px 0px rgba(0, 0, 0, 0.08));
        border-radius: 8px;
        background: rgb(255, 255, 255);
      }

      .figure-image {
        display: flex;
        align-items: center;

        figure {
          width: 50px;
          height: 52px;
          background: #edf2f4;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
        }
      }
    }

    .daybutton {
      background: transparent;
      font-size: 14px;
      font-weight: 500;
      padding: 5px 10px !important;
      color: #121d32;
      border: 2px solid #000;
      border-radius: 10px;

      &:hover {
        background: #000;
        color: #fff;
      }
    }
  }

  table {
    @media (max-width: 991px) {
      min-width: 1050px;
    }

    button {
      padding: 0;
      min-width: auto;

      svg {
        color: #828282;
      }
    }
  }

  .tableProfile {
    display: flex;
    align-items: center;

    img {
      // border-radius: 50%;
      margin: 0 7px 0 0;
    }
  }

  .MuiButton-root {
    padding: 0 !important;
  }

  .activeButton {
    background: #ecffec;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    color: #6abd8b;
    padding: 4px 18px;
    border: none;
    cursor: pointer;

    // &:hover {
    //   background: #6abd8b;
    //   color: #fff;
    // }
    text-align: center;
  }

  .activeButton1 {
    background: #ecffec;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    color: #2196f3;
    padding: 4px 18px;
    border: none;
    cursor: pointer;

    // &:hover {
    //   background: #6abd8b;
    //   color: #fff;
    // }
    text-align: center;
  }

  .activeButton2 {
    background: #ecffec;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    color: #f44336;
    padding: 4px 18px;
    border: none;
    cursor: pointer;

    // &:hover {
    //   background: #6abd8b;
    //   color: #fff;
    // }
    text-align: center;
  }

  .activeButton3 {
    background: #ecffec;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    color: #d84315;
    padding: 4px 18px;
    border: none;
    cursor: pointer;

    // &:hover {
    //   background: #6abd8b;
    //   color: #fff;
    // }
    text-align: center;
  }

  .activeButton4 {
    background: #ecffec;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    color: #6abd8b;
    padding: 4px 18px;
    border: none;
    cursor: pointer;

    // &:hover {
    //   background: #6abd8b;
    //   color: #fff;
    // }
    text-align: center;
  }

  .activeButtonClient {
    padding: 4px 3px;
  }

  .inactive {
    background: #f5e1e1;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    color: #f50d0d;
    padding: 4px 29px;
    border: none;
    cursor: pointer;
  }

  .redButton {
    background: var(--errorDark) !important;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    color: #fff;
    padding: 4px 33px;
    border: none;
    cursor: pointer;

    &:hover {
      background: var(--errorDark) !important;
      color: #fff;
    }
  }

  .completeButton {
    background: #ecf2ff;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    color: #407bff;
    padding: 4px 33px;
    border: none;
    cursor: pointer;
    // hover
    // &:hover {
    //   background: #407bff;
    //   color: #fff;
    // }
  }

  .supervisor {
    background: #fff5eb;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    color: #efa037;
    padding: 4px 33px;
    border: none;
    cursor: pointer;
    // hover
    // &:hover {
    //   background: #efa037;
    //   color: #fff;
    // }
  }

  .supervisor_admin {
    padding: 4px 9px;
  }

  .pendingButton {
    background: rgba(255, 244, 224, 0.8);
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    color: #fd8900;
    padding: 4px 23px;
    border: none;
    cursor: pointer;
    // hover
    // &:hover {
    //   background: #fd8900;
    //   color: #fff;
    // }
  }

  .pendingButtons {
    background: rgba(255, 244, 224, 0.8);
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    color: #fd8900;
    padding: 4px 26px;
    border: none;
    cursor: pointer;
    // hover
    // &:hover {
    //   background: #fd8900;
    //   color: #fff;
    // }
    width: 128px;
  }

  .ActiveButtons {
    background: #edfff4;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    color: #6abd8b;
    padding: 4px 27px;
    border: none;
    cursor: pointer;
    width: 129px;
  }

  .cancelButton {
    background: #f1e6e2;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    color: #d90429;
    padding: 4px 23px;
    border: none;
    cursor: pointer;
  }

  .cancelButtons {
    background: #f1e6e2;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    color: #d90429;
    padding: 4px 15px;
    border: none;
    cursor: pointer;
    width: 127px;
  }

  .blockButton {
    background: #fde9ec;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    color: #d90429;
    padding: 4px 33px;
    border: none;
    cursor: pointer;
    // hover
    // &:hover {
    //   background: #d90429;
    //   color: #fff;
    // }
  }

  .completeButton {
    background: #f1f3f7;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    color: #407bff;
    padding: 4px 46px;
    border: none;
    cursor: pointer;
  }

  .draftButton {
    background: #eeeeee;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    color: #8d99ae;
    padding: 4px 33px;
    border: none;
    cursor: pointer;

    // &:hover {
    //   background: #8d99ae;
    //   color: #fff;
    // }
  }

  .userName {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    color: #192a3e;
  }

  .tagButton {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-weight: 600;
      font-size: 10.92px;
      color: #695eef;
      padding: 3px 7px;
      background: rgba(105, 94, 239, 0.1);
      border-radius: 4px;
      margin: 0;
    }
  }
}

//////////////

.faqModal {
  .MuiPaper-rounded {
    padding: 0;
    position: relative;
    max-width: 1110px;
    flex-basis: 1110px;
    background: #ffffff;
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 24px;

    @media (max-width: 575px) {
      padding: 10px;
    }
  }

  .MuiIconButton-root {
    display: none;
  }
}

.addcategory {
  .MuiPaper-rounded {
    padding: 0;
    position: relative;
    max-width: 600px;
    flex-basis: 600px;
    background: #ffffff;
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 24px;

    @media (max-width: 575px) {
      padding: 10px;
    }
  }

  .MuiIconButton-root {
    display: none;
  }
}

.headerMain {
  @media (max-width: 899px) {
    padding: 10px 10px;
    background-color: #ffffff;

    .MuiIconButton-root {
      position: absolute;
      top: 50%;
      background: #de1928;
      display: flex;
      align-items: center;
      left: 10px;
      transform: translate(0%, -50%);

      @media (max-width: 375px) {
        padding: 3px;
      }
    }
  }
}

.customEditor {
  select {
    padding: 4px 12px !important;
  }
}

#adsFilter {
  ul {
    li {
      .MuiListItemIcon-root {
        justify-content: flex-end;
      }

      svg {
        font-size: 14px;
      }
    }
  }
}

.button_blue_delete {
  padding: 9px;
}

.blockModal_modal {
  img {
    position: absolute;
    top: 9px;
    right: 14px;

    background: #e10505e3;
    padding: 6px;
    border-radius: 30px;
    cursor: pointer;
  }
}

.selectbg {
  background: url(../public/assets/images/pagibg-one.svg) no-repeat right 16px center;
}

.paginationimgone1 {
  ul {
    li:first-child {
      button {
        &:hover {
          opacity: 0.1 !important;
        }
      }
    }

    li:last-child {
      button {
        &:hover {
          opacity: 0.5 !important;
        }
      }
    }
  }
}

.active_btn {
  button {
    background: #13bd38;

    &:hover {
      background: #13bd38;
    }
  }
}

.inputWrapper {
  input[type="date"] {
    width: 100%;
  }
}

.profileDetails {
  h4 {
    span {
      margin-right: 27px !important;
    }
  }
}

.date_picker {
  .MuiFormControl-root {
    width: 100%;
  }
}

.date_picker {
  .MuiInputBase-input {
    border: 0 !important;
  }
}

.date_picker {
  svg {
    position: absolute;
    right: 34px;
    margin-top: 16px;
  }
}

.upload-image {
  figure {
    img {
      border-radius: 50%;
      padding: 2px;
    }
  }
}

.up-wrap {
  input {
    max-width: 200px;
  }
}

.inputNumber {
  .MuiInputBase-input {
    height: 12px;
    border: 1px solid #8d99ae;
    border-radius: 4px;
  }

  .MuiOutlinedInput-notchedOutline {
    display: none;
  }
}

.profile_images {
  .MuiAvatar-root {
    margin-right: 7px;
  }
}

.new_calender {
  svg {
    position: absolute;
    right: -1px;
    margin-top: 16px;
    top: -23px;
  }

  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.6;
    color: #2b2d42;
    margin: 0 0 6px;

    sup {
      color: #ec3c46;
    }
  }
}

.linkiconone {
  width: 21px;
  height: 21px;
  opacity: 0;
  position: absolute;
  left: 0;
  z-index: 9;
  cursor: pointer;
}

.image_file {
  position: relative;
  margin: 0 0 0 10px;

  img {
    padding: 20px;
    border-radius: 10px;
  }

  svg {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
  }
}

.react-datepicker__navigation--years-previous {
  position: relative !important;
  background: url(../public/assets/images/down-filled-triangular-arrow.png) no-repeat center !important;
  background-size: 12px !important;
  top: 0 !important;
}

.react-datepicker__navigation--years-upcoming {
  position: relative !important;
  background: url(../public/assets/images/arrow-up.png) no-repeat center !important;
  background-size: 12px !important;
  top: 0 !important;
}

.tabs_apex {
  button {
    border: 0;
    margin-left: 18px;
    background: transparent;
  }

  .active {
    background: #bdb5b5bf;
    padding: 9px 15px;
    border-radius: 31px;
    border: 0;
  }
}

/****Modal***/

.verify-modal {
  position: relative;
  width: 500px;
  max-width: 500px;
  padding: 30px;
  text-align: center;
  padding-top: 60px;

  @media (max-width: 767px) {
    max-width: 90vw;
    width: 90vw;
  }

  .close-ico {
    position: absolute;
    right: 15px;
    top: 15px;

    &:hover {
      background: transparent;
      opacity: 0.8;
    }
  }

  .ico_box {
    margin-bottom: 10px;
  }

  h3 {
    color: #2c2e3e;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  p {
    color: #6c6c6c;
  }

  .modal-content-one {
    p {
      max-width: 80%;
      margin: 0px auto;
    }
  }

  .btn-holder {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    button {
      margin: 0 15px;
      min-width: 176px;
      padding: 5px;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      opacity: 1;

      &:hover {
        opacity: 0.8;
      }

      @media (max-width: 479px) {
        min-width: 100px;
        padding: 5px 12px;
      }

      &.cancel-btn {
        color: #d90429;
        border: 2px solid #d90429;
        background: transparent;
      }

      &.ok-btn {
        color: #fff;
        border: 2px solid transparent;
        background: #2b2d42;
      }
    }
  }
}

.MuiDialog-container {
  .MuiDialog-paper {
    @media (max-width: 767px) {
      margin: 0;
    }
  }
}

.MuiRating-root {
  color: #ef233c;
  border-color: #ef233c;

  svg {
    fill: #ef233c;
  }
}

.providerprofilecard_rating {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.open_eye {
  position: absolute;
  top: 9px;
  right: 16px;
}

.textareanew {
  textarea {
    border: 1px solid #00000045;
  }
}

.MuiOutlinedInput-notchedOutline {
  border-color: unset !important;
}

.download_button {
  max-width: 355px;
  margin: 0 auto;
}

.open_eyes {
  float: right;
  position: relative;
  bottom: 33px;
  right: 10px;
}

.change_password {
  input {
    text-transform: none !important;
  }
}

.input-wraps {
  select {
    padding: 10px 13px !important;
  }
}

.loaders_admin {
  width: 300px;
  margin: 0 auto;
}

.fileInner1 {
  position: relative;

  @media (min-width: 1200px) {
    //top: -79px;
  }
}

.blockModalTxt {
  margin-bottom: 30px;
}

.datePicker_icon {
  .MuiIconButton-root {
    display: block;
  }

  input[type="text"] {
    height: 30px;
  }

  &.date_picker {
    svg {
      position: absolute;
      right: 2px;
      margin-top: 0;
      top: -4px;
    }
  }
}

#disabled {
  pointer-events: none;
  position: relative;
  cursor: not-allowed;
  opacity: 0.3;
}

.doc_cv {
  svg {
    right: auto !important;
    margin-left: 10px;
  }
}

.chips_chip {
  fieldset {
    border: 0px !important;
  }
}

.featured-providers {
  button {
    margin-left: -13px;
    margin-right: 16px !important;
  }
}

.date_selection {
  display: flex;
}

.closebutton {
  background: #8d99ae;
  color: #fff;
  border-radius: 6px;
  padding: 9px 24px;

  p {
    color: #fff !important;
  }

  &:hover {
    box-shadow: inset 0 5px 30px 0 #fff;
    transition: all ease 0.4s;
    background: #d90429;
  }
}

.close-icon-main {
  position: absolute;
  right: 15px;
  top: 15px;

  &:hover {
    box-shadow: inset 0 5px 30px 0 #fff;
    transition: all ease 0.4s;
    color: #d90429;
    cursor: pointer;
  }
}

.create-calender {
  align-items: center;

  p {
    padding-left: 10px;
    padding-right: 10px;
    margin: 0px;
  }

  input {
    height: auto;
    border: 0px;
  }
}

.project-details-img {
  height: 100px;
}

.assign_staff {
  text-align: left;
}

.button_assign_ticket {
  @media (min-width: 1024px) {
    width: 873px;
    text-align: right;
    position: relative;
    right: 25px;
  }

  .MuiChip-root {
    background: linear-gradient(129.26deg,
        #ff3840 5.68%,
        #953764 62.56%,
        #5d3252 95.65%);
  }
}

.breadcums_bread {
  width: 50%;
}

.Assign_name {
  width: 30%;
}